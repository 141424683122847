"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardResource_1 = require("@/common/CardResource");
const cssClass = {
    [CardResource_1.CardResource.ANIMAL]: 'card-resource-animal',
    [CardResource_1.CardResource.MICROBE]: 'card-resource-microbe',
    [CardResource_1.CardResource.FIGHTER]: 'card-resource-fighter',
    [CardResource_1.CardResource.SCIENCE]: 'card-resource-science',
    [CardResource_1.CardResource.FLOATER]: 'card-resource-floater',
    [CardResource_1.CardResource.ASTEROID]: 'card-resource-asteroid',
    [CardResource_1.CardResource.PRESERVATION]: 'card-resource-preservation',
    [CardResource_1.CardResource.CAMP]: 'card-resource-camp',
    [CardResource_1.CardResource.DISEASE]: 'card-resource-disease',
    [CardResource_1.CardResource.RESOURCE_CUBE]: 'card-resource-cube',
    [CardResource_1.CardResource.DATA]: 'card-resource-data',
    [CardResource_1.CardResource.SYNDICATE_FLEET]: 'card-resource-syndicate-fleet',
    [CardResource_1.CardResource.VENUSIAN_HABITAT]: 'card-resource-venusian-habitat',
    [CardResource_1.CardResource.SPECIALIZED_ROBOT]: 'card-resource-specialized-robot',
    [CardResource_1.CardResource.SEED]: 'card-resource-seed',
    [CardResource_1.CardResource.AGENDA]: 'card-resource-agenda',
    [CardResource_1.CardResource.ORBITAL]: 'card-resource-orbital',
    [CardResource_1.CardResource.GRAPHENE]: 'card-resource-graphene',
    [CardResource_1.CardResource.HYDROELECTRIC_RESOURCE]: 'card-resource-hydroelectric-resource',
    [CardResource_1.CardResource.CLONE_TROOPER]: 'card-resource-clone-trooper',
    [CardResource_1.CardResource.TOOL]: 'card-resource-tool',
};
exports.default = vue_1.default.extend({
    name: 'CardResourceCounter',
    props: {
        amount: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    computed: {
        cssClass() {
            return cssClass[this.type];
        },
    },
});
