"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardRenderData_vue_1 = require("@/client/components/card/CardRenderData.vue");
const CardParty_vue_1 = require("@/client/components/card/CardParty.vue");
const ClientGlobalEventManifest_1 = require("@/client/turmoil/ClientGlobalEventManifest");
const CardDescription_vue_1 = require("@/client/components/card/CardDescription.vue");
exports.default = vue_1.default.extend({
    name: 'global-event',
    components: {
        CardRenderData: CardRenderData_vue_1.default,
        CardParty: CardParty_vue_1.default,
        CardDescription: CardDescription_vue_1.default,
    },
    props: {
        globalEventName: {
            type: String,
        },
        type: {
            type: String,
        },
    },
    data() {
        const globalEvent = (0, ClientGlobalEventManifest_1.getGlobalEvent)(this.globalEventName);
        if (globalEvent === undefined) {
            throw new Error(`Can't find card ${this.globalEventName}`);
        }
        return {
            renderData: globalEvent.renderData,
            revealed: globalEvent.revealedDelegate,
            current: globalEvent.currentDelegate,
            description: globalEvent.description,
        };
    },
    methods: {
        getCardRenderer() {
            return this.renderData;
        },
        partyNameClass(partyName) {
            if (partyName === undefined) {
                return '';
            }
            return 'event-party--' + partyName.toLowerCase().split(' ').join('_');
        },
        getClass() {
            const common = 'global-event';
            switch (this.type) {
                case 'coming':
                    return common + ' global-event--coming';
                case 'current':
                    return common + ' global-event--current';
                default:
                    return common;
            }
        },
    },
    computed: {
        eventNameStyle() {
            if (this.globalEventName.length > 24) {
                return 'global-event-name--narrow';
            }
            return '';
        },
    },
});
